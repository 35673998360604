import React from 'react';
import TestRunner from '../testRunner/testRunner';
// import Info from './info';
import { connect } from 'react-redux';
import store from '../..';
import { pixelTabToggle } from '../../actions';

class PixelTab extends React.Component {
  render() {
    return (
      <div
        className={`pixeltab h-50vh position-relative row bg-light pt-4 ${
          this.props.tests.expanded ? '' : ' collapsed'
        }`}
      >
        <TestRunner></TestRunner>
        {/* <Info></Info> */}
        <span
          className="down-icon position-absolute text-dark p-2"
          onClick={() => {
            store.dispatch(pixelTabToggle());
          }}
        >
          &#8249;
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tests: state.tests,
  };
};

export default connect(mapStateToProps)(PixelTab);
