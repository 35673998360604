import React from 'react';
import PixelTabReporter, {
  TEST_STATE_FAILED,
  TEST_STATE_PASSED,
} from '../../shared/reporters/pixelTab';
import { connect } from 'react-redux';
import TestResult from './testResult';
import mocha from 'mocha';
import { TESTS_DONE } from '../../reducers/pixelTabReducer';
import store from '../..';
import {
  pixelTabAddTestSuiteId,
  pixelTabTestFilter,
} from '../../actions/pixelTab/pixelTab';

class TestRunner extends React.Component {
  async componentDidMount() {
    mocha.reporter(PixelTabReporter);
    mocha.setup({
      ui: 'bdd',
      cleanReferencesAfterRun: false,
    });
    mocha.checkLeaks();

    if (!this.props.testSuiteCache[this.props.testSuiteId]) {
      await this.downloadSuite();
      store.dispatch(pixelTabAddTestSuiteId(this.props.testSuiteId));
    }

    this.executeSuite();
  }

  async downloadSuite() {
    const normalizedTestSuiteId = this.props.testSuiteId.replace(/-/g, '/');

    try {
      const { registerTests } = await import(
        `../../tests/${normalizedTestSuiteId}`
      );

      registerTests();
    } catch (error) {
      console.warn(
        'Nu am reusit sa incarcam rezolvarile pentru acest exercitiu.',
        error,
      );
    }
  }

  executeSuite() {
    mocha.run();
  }

  setFilter = (filter) => {
    store.dispatch(pixelTabTestFilter(filter));
  };

  renderMessage(elligibleTests) {
    const { tests } = this.props;
    if (tests.filterBy === '') {
      return null;
    }

    if (elligibleTests.length <= 0 && tests.filterBy === TEST_STATE_PASSED) {
      return 'Nu ai rezolvat nici o cerinta.';
    }

    if (elligibleTests.length <= 0 && tests.filterBy === TEST_STATE_FAILED) {
      return 'Ai rezolvat toate cerintele.';
    }
  }

  renderTestResults() {
    const { tests } = this.props;

    if (tests.status !== TESTS_DONE) {
      return null;
    }

    const elligibleTests = tests.all.filter((test) => {
      if (tests.filterBy === '') {
        return true;
      }

      return test.state === tests.filterBy;
    });

    return (
      <div className="h-50vh d-flex flex-column align-items-center">
        <h5 className="pixeltab__h5 opacity-7 mb-0">
          Ai rezolvat{' '}
          <span className="font-weight-bold bg-light-green px-1">
            {tests.stats.passes}
          </span>{' '}
          din cele{' '}
          <span className="font-weight-bold bg-light-gray px-1">
            {tests.stats.passes + tests.stats.failures}
          </span>{' '}
          cerinte!
        </h5>

        <div className="d-flex w-85 justify-content-between align-items-center mb-2">
          <div className="pixeltab__all-badges">
            <div className="">
              <button
                className={`btn badge bg-light-green ${
                  tests.filterBy === TEST_STATE_PASSED
                    ? 'border border-dark'
                    : ''
                }`}
                title="Vezi doar cerintele corecte"
                onClick={() => this.setFilter(TEST_STATE_PASSED)}
              >
                👍
              </button>
              <button
                className={`btn badge bg-striped mx-3 ${
                  !tests.filterBy ? 'border border-dark' : ''
                }`}
                title="Vezi toate cerintele"
                onClick={() => this.setFilter('')}
              >
                👍 👎
              </button>
              <button
                className={`btn badge bg-light-pink ${
                  tests.filterBy === TEST_STATE_FAILED
                    ? 'border border-dark'
                    : ''
                }`}
                title="Vezi doar cerintele gresite"
                onClick={() => this.setFilter(TEST_STATE_FAILED)}
              >
                👎
              </button>
            </div>
          </div>

          <div className="d-flex align-items-center" style={{ gap: '8px' }}>
            <label className="m-0">Pixeltab - v 1.1</label>
            <button className="btn btn-primary" onClick={this.executeSuite}>
              Verifica iar
            </button>
          </div>
        </div>

        <div className="overflow-auto opacity-7 w-85 h-65">
          {this.renderMessage(elligibleTests)}

          {elligibleTests.map((test) => {
            return <TestResult key={test.id} test={test}></TestResult>;
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="test-runner col border-right pl-2 pr-0">
        {this.renderTestResults()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testSuiteId: state.dom.testSuiteId,
    tests: state.tests,
    testSuiteCache: state.testSuiteCache,
  };
};

export default connect(mapStateToProps)(TestRunner);
